<template>
  <div class="module_one_block">
    <div class="module_one_header_block">
      <div class="module_one_header_container">
        <div class="module_one_header_label">
          {{ course.title }}
        </div>
      </div>
    </div>
    <div class="module_one_main_block">
      <div class="module_one_main_container">
        <div class="module_one_content_container">
          <div class="module_one_plot m-0" v-if="!course">
            <el-skeleton :rows="6" />
          </div>
          <div class="module_one_plot m-0" v-else>
            <router-link
              :to="{ name: 'about-course', slug: course.slug }"
              class="module_one_plot_module not-decoration"
              @click.native="showReviewBlock = false"
            >
              {{ $t("About_course") }}
            </router-link>
            <router-link
              v-for="module in course.modules"
              :to="{
                name: 'course-module',
                params: { slug: course.slug, module: module.id },
              }"
              :class="{ active: $route.params.module === module.id }"
              class="module_one_plot_module not-decoration"
              @click.native="showReviewBlock = false"
            >
              {{ module.title }}
            </router-link>
            <router-link
              v-if="course.quiz"
              :to="{ name: 'final-testing', params: { slug: course.slug } }"
              class="module_one_plot_module"
            >
              {{ $t("Final_testing") }}
            </router-link>
            <router-link
              v-if="showReviewCourseBlock"
              :to="{
                name: 'course-review',
                params: { slug: course.slug, course: course.id },
              }"
              class="module_one_plot_module"
            >
              Отзыв по курсу
            </router-link>
          </div>
        </div>
        <div
          class="module_one_desc"
          v-if="currentModule && currentModule.themes.length"
        >
          <div v-if="!showReviewBlock">
            <div v-for="theme in currentModule.themes" v-if="currentModule">
              <router-link
                :to="{
                  name: 'course-theme',
                  params: {
                    slug: course.slug,
                    module: $route.params.module,
                    theme: theme.id,
                  },
                }"
                v-if="theme.available"
                :class="{
                  module_one_desc_item: theme.progress === 100,
                  module_one_desc_process: theme.progress !== 100,
                }"
                class="cursor-pointer text-decoration-none"
              >
                <div
                  :class="{
                    module_one_desc_item_label: theme.progress === 100,
                    module_one_desc_process_label: theme.progress !== 100,
                  }"
                >
                  {{ theme.name }}
                </div>
                <div
                  class="module_one_desc_item_result"
                  v-if="theme.progress === 100"
                >
                  <div class="module_one_desc_item_text">
                    {{ $t("Passed_course_module") }}
                  </div>
                  <div class="module_one_desc_item_mark">
                    {{ $t("Result") }}: {{ theme.result }}%
                  </div>
                </div>

                <div v-else>
                  <div v-if="theme.progress === 0" class="module_one_start_btn">
                    <button class="module_one_desc_btn_start">
                      {{ $t("Begin") }}
                    </button>
                  </div>
                  <div v-else class="module_one_desc_bar">
                    <div
                      class="module_one_desc_back"
                      :style="{ width: getProgressWidth(theme.progress) }"
                    >
                      {{ $t("In_process") }}
                    </div>
                    <div
                      class="module_one_desc_result"
                      :class="
                        theme.progress > 85
                          ? 'm-right-' + (100 - theme.progress)
                          : ''
                      "
                      :style="{
                        color: theme.progress > 85 ? '#FFFFFF' : '#305AA6',
                      }"
                    >
                      {{ theme.progress }}%
                    </div>
                  </div>
                </div>
              </router-link>
              <div v-else class="module_one_desc_no_access">
                <div class="module_one_desc_no_access_label">
                  {{ theme.name }}
                </div>
                <div class="module_one_desc_no_access_result">
                  {{
                    $t(
                      "You_cannot_start_until_you_have_completed_the_previous_topic",
                    )
                  }}
                </div>
              </div>
            </div>

            <div
              class="module_one_desc_process"
              v-if="showReview && !currentReview?.is_answered"
            >
              <div class="module_one_desc_process_label">Отзыв</div>
              <div class="review_start_btn" @click="showReviewBlock = true">
                {{ $t("Begin") }}
              </div>
            </div>
            <div
              class="module_one_desc_item"
              v-if="showReview && currentReview?.is_answered"
            >
              <div class="module_one_desc_item_label">Отзыв</div>
              <div class="module_one_desc_item_text">
                Отзыв получен. Спасибо!
              </div>
            </div>
            <div class="module_one_desc_no_access" v-if="!showReview">
              <div class="module_one_desc_no_access_label">Отзыв</div>
              <div class="module_one_desc_no_access_result">
                Пройдите весь модуль, чтобы оставить отзыв
              </div>
            </div>

            <div
              v-if="
                currentModule &&
                currentModule.quiz &&
                currentModule.quiz.available
              "
              class="module_two_desc_test"
            >
              <div
                class="module_two_desc_test_time"
                v-if="!currentModule.quiz.user_answered"
              >
                0 минут
              </div>
              <div class="module_two_desc_test_time result" v-else>
                Результат
              </div>
              <div
                class="module_two_desc_test_label"
                :class="{ passed: currentModule.quiz.user_answered }"
              >
                {{ currentModule.quiz.title }}
              </div>
              <div
                class="module_two_desc_test_btn"
                v-if="currentModule.quiz.user_answered"
              >
                <!--              <a @click.prevent="" href="#" class="module_two_desc_test_btn_start passed">Пройдено</a>-->
                <router-link
                  :to="{
                    name: 'course-exercise',
                    params: { slug: course.slug, quiz: currentModule.quiz.id },
                  }"
                  class="passed pointer module_two_desc_test_btn_start"
                >
                  Пройдено
                </router-link>
                <span>
                  Пройдено:
                  {{ getDate(currentModule.quiz.user_answered.created_at) }}
                </span>
                <span class="mobile_view_separation">&nbsp;|&nbsp;</span>
                <span class="text-primary">
                  Проходной балл: {{ currentModule.quiz.passing_score }}
                </span>
                <div class="result-score-text">
                  {{ currentModule.quiz.user_result }}
                </div>
                <div class="result-score-text-mobile">
                  Результат: {{ currentModule.quiz.user_result }}
                </div>
              </div>
              <div v-else class="module_two_desc_test_btn">
                <router-link
                  :to="{
                    name: 'course-exercise',
                    params: { slug: course.slug, quiz: currentModule.quiz.id },
                  }"
                  class="pointer module_two_desc_test_btn_start"
                >
                  <span v-if="currentModule.quiz.percent">Пройден</span>
                  <span v-else>Начать</span>
                </router-link>
              </div>
            </div>
            <div
              v-if="
                currentModule &&
                currentModule.quiz &&
                !currentModule.quiz.available
              "
              class="module_one_desc_no_access"
            >
              <div class="module_one_desc_no_access_label">
                {{ currentModule.quiz.title }}
              </div>
              <div class="module_one_desc_no_access_result">
                {{
                  $t(
                    "You_cannot_start_until_you_have_completed_the_previous_topic",
                  )
                }}
              </div>
            </div>
            <div class="module_one_desc_btn module_one_buttons">
              <a
                @click="goToPrevModule"
                class="module_one_desc_btn_back cursor-pointer"
              >
                {{ $t("Back") }}
              </a>
              <a
                v-if="showNext"
                @click="goToNextModule"
                class="module_one_desc_btn_next cursor-pointer"
              >
                {{ $t("Further") }}
              </a>
            </div>
          </div>

          <div class="review_module_desc" v-if="showReviewBlock">
            <div class="theme_one_desc_title">
              Отзыв по модулю {{ currentModuleTitle }}
            </div>
            <div class="review_module_desc_text_container">
              <div class="theme_one_desc_text" v-if="currentReview">
                {{ currentReview.questions[currentQuestion - 1].text }}
              </div>

              <div
                class="review_rating_block"
                v-if="
                  currentReview &&
                  currentReview.questions[currentQuestion - 1].type === `Rating`
                "
              >
                <span
                  class="review_rating_item"
                  @click="rating = 1"
                  :class="{ active: rating === 1 }"
                  >1</span
                >
                <span
                  class="review_rating_item"
                  @click="rating = 2"
                  :class="{ active: rating === 2 }"
                  >2</span
                >
                <span
                  class="review_rating_item"
                  @click="rating = 3"
                  :class="{ active: rating === 3 }"
                  >3</span
                >
                <span
                  class="review_rating_item"
                  @click="rating = 4"
                  :class="{ active: rating === 4 }"
                  >4</span
                >
                <span
                  class="review_rating_item"
                  @click="rating = 5"
                  :class="{ active: rating === 5 }"
                  >5</span
                >
              </div>
              <div
                class="review_rating_block"
                style="flex-direction: column"
                v-if="
                  currentReview &&
                  currentReview.questions[currentQuestion - 1].type === `Rating`
                "
              >
                <span class="theme_one_desc_text">Оставьте свой отзыв</span>
                <textarea
                  class="type_raw_answers_text"
                  rows="6"
                  v-model="ratingText"
                >
                </textarea>
              </div>

              <div
                class="review_question_options_block"
                v-if="
                  currentReview.questions[currentQuestion - 1].type ===
                  `TYPE_SINGLE`
                "
              >
                <el-radio
                  style="white-space: normal"
                  v-for="answer in currentReview.questions[currentQuestion - 1]
                    .answers"
                  v-model="checkList"
                  :label="answer.id"
                  :key="answer.id"
                >
                  {{ answer.text_cleared }}
                </el-radio>
              </div>
              <div
                class="review_question_options_block"
                v-if="
                  currentReview.questions[currentQuestion - 1].type ===
                  `TYPE_MULTIPLE`
                "
              >
                <el-checkbox-group
                  v-model="checkList"
                  style="display: flex; flex-direction: column"
                >
                  <el-checkbox
                    v-for="answer in currentReview.questions[
                      currentQuestion - 1
                    ].answers"
                    :label="answer.id"
                    :key="answer.id"
                  >
                    {{ answer.text_cleared }}
                  </el-checkbox>
                </el-checkbox-group>
              </div>
              <div
                class="review_question_options_block"
                v-if="
                  currentReview.questions[currentQuestion - 1].type ===
                  `TYPE_RAW_ANSWERS`
                "
              >
                <textarea
                  class="type_raw_answers_text"
                  rows="6"
                  v-model="checkList"
                >
                </textarea>
              </div>
            </div>
            <!--            <el-pagination-->
            <!--              background-->
            <!--              layout="pager"-->
            <!--              :current-page.sync="currentQuestion"-->
            <!--              :total="currentReview.questions.length * 10">-->
            <!--            </el-pagination>-->
            <div class="theme_one_desc_btn">
              <a
                href="#"
                class="theme_one_desc_btn_next"
                v-if="currentReview.questions.length !== currentQuestion"
                @click="nextPage"
              >
                Далее
              </a>
              <a
                @click="sendReview"
                class="review_post_btn"
                v-if="currentReview.questions.length === currentQuestion"
              >
                Отправить отзыв
              </a>
            </div>
          </div>
        </div>

        <div v-else class="module_one_desc">
          <div class="module_two_desc_item">
            <div>
              <div
                class="cursor-pointer text-decoration-none module_one_desc_item"
              >
                <div class="pt-4">{{ $t("Data_not_found") }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "CourseModule",
  data() {
    return {
      course: false,
      showReview: false,
      showReviewBlock: false,
      currentQuestion: 1,
      answersArr: {},
      checkList: [],
      rating: "",
      ratingText: "",
      showReviewCourseBlock: false,
    };
  },
  methods: {
    nextPage() {
      if (this.checkList.length === 0 && this.rating === "") {
        return Vue.toastr({
          message: "Error",
          description: "Выберите ответ",
          type: "error",
        });
      }

      let questionData;

      const question = this.currentReview.questions[this.currentQuestion - 1];

      if (question.type === "TYPE_RAW_ANSWERS") {
        questionData = {
          review_id: this.currentReview.id,
          review_question_id: question.id,
          answer: this.checkList,
          rating: 0,
        };
      } else if (question.type === "Rating") {
        questionData = {
          review_id: this.currentReview.id,
          rating: this.rating,
          answer: this.ratingText,
        };
      } else {
        questionData = {
          review_id: this.currentReview.id,
          review_question_id: question.id,
          review_question_answer_id: this.checkList,
          answer: this.checkList,
          rating: 0,
        };
      }

      // let apiRoot = `https://apibirgeoqu.elumiti.kz`
      let apiRoot = API_ROOT;
      this.$http
        .post(`${apiRoot}/api/review/make`, questionData)
        .then((res) => {
          console.log("res", res);
          if (question.type === "Rating") {
            this.checkList = [];
            this.currentQuestion = 1;
            this.showReviewBlock = false;
            this.rating = "";
            Vue.toastr({
              message: "Success",
              description: "Отзыв отправлен",
              type: "success",
            });
          }
          this.checkList = [];
        })
        .catch((err) => {
          console.log("err", err);
        });

      this.currentQuestion++;
    },
    sendReview() {
      let sendData;
      const review_id = this.currentReview.id;

      if (this.currentReview.has_rating === 1) {
        if (this.rating.length === 0 || this.ratingText.length === 0) {
          return Vue.toastr({
            message: "Error",
            description: "Выберите ответ",
            type: "error",
          });
        }

        sendData = { review_id, rating: this.rating, answer: this.ratingText };
      } else {
        if (this.checkList.length === 0) {
          return Vue.toastr({
            message: "Error",
            description: "Выберите ответ",
            type: "error",
          });
        }

        const question = this.currentReview.questions[this.currentQuestion - 1];

        console.log("question", question);

        if (question.type === "TYPE_RAW_ANSWERS") {
          sendData = {
            review_id: this.currentReview.id,
            review_question_id: question.id,
            answer: this.checkList,
            rating: 0,
          };
        } else {
          sendData = {
            review_id: this.currentReview.id,
            review_question_id: question.id,
            review_question_answer_id: this.checkList,
            answer: this.checkList,
            rating: 0,
          };
        }
      }

      // let apiRoot = `https://apibirgeoqu.elumiti.kz`
      let apiRoot = API_ROOT;
      this.$http
        .post(`${apiRoot}/api/review/make`, sendData)
        .then((res) => {
          console.log("res", res);
          this.getCourseDetails();
          this.checkList = [];
          this.currentQuestion = 1;
          this.showReviewBlock = false;
          this.rating = "";
          Vue.toastr({
            message: "Success",
            description: "Отзыв отправлен",
            type: "success",
          });
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    getCourseDetails() {
      let apiRoot = API_ROOT;
      let slug = this.$route.params.slug;
      this.$http
        .get(`${apiRoot}/api/course/${slug}`)
        .then((res) => {
          this.course = res.body;

          if (this.course.quiz) {
            this.showReviewCourseBlock = true;
          } else {
            this.showReviewCourseBlock = this.course.modules.every((module) =>
              module.themes.every((theme) => theme.progress === 100),
            );
          }

          this.showReview = this.currentModule.themes.every(
            (theme) => theme.progress === 100,
          );

          this.course.reviews = this.course.reviews
            .filter((item) => item.type === 2)
            .map((review, index) => {
              if (this.course.modules[index]) {
                review.module_id = this.course.modules[index].id;
              }
              return review;
            });

          if (this.currentReview.has_rating === 1) {
            this.currentReview.questions.push({
              type: "Rating",
              text: "Оцените данный модуль!",
              answers: [],
            });
          }

          if (this.currentReview.has_rating === 1) {
            this.currentReview.questions.push({
              type: "Rating",
              text: "Оцените данный модуль!",
              answers: [],
            });
          }

          console.log("this.course", this.course);
        })
        .catch((err) => console.log(err))
        .finally(() => {});
    },
    goToPrevModule() {
      let prevModule = this.getModule("prev");
      if (prevModule && prevModule.id) {
        this.$router.push({
          name: "course-module",
          params: {
            slug: this.course.slug,
            module: prevModule.id,
          },
        });
      } else {
        this.$router.push({
          name: "about-course",
          params: {
            slug: this.course.slug,
          },
        });
      }
    },
    goToNextModule() {
      let nextModule = this.getModule("next");
      if (nextModule && nextModule.id) {
        this.$router.push({
          name: "course-module",
          params: {
            slug: this.course.slug,
            module: nextModule.id,
          },
        });
      } else {
        if (this.course.quiz) {
          this.$router.push({
            name: "final-testing",
            params: { slug: this.course.slug },
          });
        }
      }
    },
    getModule: function (direction) {
      if (!this.course.modules) return false;
      let i = 0;
      let max = this.course.modules.length;
      for (i; i < max; i += 1) {
        if (this.course.modules[i].id === this.currentModule.id) {
          return direction === "next"
            ? this.course.modules[i + 1]
            : this.course.modules[i - 1];
        }
      }
    },
    getProgressWidth(progress) {
      if (progress > 0 && progress < 40) progress = 40;
      return progress + "%";
    },
    getDate(date) {
      if (!date) return;
      const formatter = new Intl.DateTimeFormat("ru-RU", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
      });
      let d = new Date(date);
      return formatter.format(d);
    },
  },
  computed: {
    showNext() {
      let nextModule = this.getModule("next");
      if (nextModule && nextModule.id) {
        return true;
      } else {
        return false;
      }
    },
    currentModule: function () {
      if (!this.course.modules) return false;
      let currentModuleId = parseInt(this.$route.params.module);
      return this.course.modules.find(
        (module) => module.id === currentModuleId,
      );
    },
    currentModuleTitle: function () {
      return this.currentModule.title.replace(/[^0-9]/g, "");
    },
    currentReview() {
      if (!this.course.reviews) return false;
      let currentModuleId = parseInt(this.$route.params.module);
      return this.course.reviews.find(
        ({ chapter_id }) => chapter_id === currentModuleId,
      );
    },
  },
  watch: {
    // currentQuestion: function (newQuestion, oldQuestion) {
    //   console.log('newQuestion', newQuestion)
    //   console.log('oldQuestion', oldQuestion)
    //   console.log('radio', this.radio)
    //   console.log('checkList', this.checkList)
    //   const question = this.course.reviews.filter( item => item.type === 2 )[0].questions[oldQuestion - 1]
    //   const questionData = {
    //     review_id: this.course.reviews.filter( item => item.type === 2 )[0].id,
    //     review_question_id: question.id,
    //     review_question_answer_id: this.checkList,
    //     answer: this.checkList,
    //     rating: 5,
    //   }
    //   this.answersArr = questionData
    //   this.checkList = []
    //   console.log('this.answersArr', this.answersArr)
    // }
  },
  mounted() {
    this.getCourseDetails();
  },
};
</script>

<style scoped>
.not-decoration {
  text-decoration: none;
}

.cursor-pointer {
  cursor: pointer;
}

.m-right-1 {
  margin-right: 1%;
}

.m-right-2 {
  margin-right: 2%;
}

.m-right-3 {
  margin-right: 3%;
}

.m-right-4 {
  margin-right: 4%;
}

.m-right-5 {
  margin-right: 5%;
}

.m-right-6 {
  margin-right: 6%;
}

.m-right-7 {
  margin-right: 7%;
}

.m-right-8 {
  margin-right: 8%;
}

.m-right-9 {
  margin-right: 9%;
}

.m-right-10 {
  margin-right: 10%;
}

.m-right-11 {
  margin-right: 11%;
}

.m-right-12 {
  margin-right: 12%;
}

.m-right-13 {
  margin-right: 13%;
}

.m-right-14 {
  margin-right: 14%;
}

.m-right-15 {
  margin-right: 15%;
}
</style>
